import React from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import styles from "./OffersAccordionContent.module.scss";
import classnames from "classnames";
import OfferImage from "./OfferImage";
import orderBy from "lodash/orderBy";
import { OFFER_ORDER_FIELD } from "../constants/OrderFields";

export default function OffersAccordionContent({ id, eventKey, groupDescriptionItems, availableOffers, onOfferSelected }) {
    const numberOfAvailableOffers = availableOffers.length;

    return (
        <>
            <Accordion.Item eventKey={eventKey}>
                <Accordion.Header>
                    <div className={classnames("width-100", styles.accordionHeader)}>
                        <span className="flex-row flex-1 text-dark-gray">
                            {groupDescriptionItems.map(({ productName, subtext }, index) => {
                                const shouldRenderAnd = index < groupDescriptionItems.length - 1;
                                return (
                                    <div
                                        key={`${id}-accordion-item-header-${index}`}
                                        className={classnames("position-relative", styles.groupDescriptionItem)}
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: productName }} />
                                        {shouldRenderAnd && (
                                            <span className={styles.and}>
                                                and
                                            </span>
                                        )}
                                        <div className={styles.productNameSubtext}>
                                            {subtext}
                                        </div>
                                    </div>
                                );
                            })}
                        </span>
                        <span className={classnames("text-sm", styles.numberOfAvailableOffers)}>{numberOfAvailableOffers} Offer{numberOfAvailableOffers === 1 ? "" : "s"} Available</span>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="flex-column spaced-content-vertical">
                        {orderBy(availableOffers, OFFER_ORDER_FIELD).map(offer => (
                            <div key={`product-image-${offer.couponLibraryOptionOfferId}`}>
                                <OfferImage
                                    imageSrc={offer.clientDisplayImageFileName}
                                    altText={offer.sidebarTitle}
                                    onClick={() => onOfferSelected(offer)}
                                />
                            </div>
                        ))}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </>
    );
}

OffersAccordionContent.propTypes = {
    groupDescriptionItems: PropTypes.arrayOf(PropTypes.shape({
        productName: PropTypes.string,
        subtext: PropTypes.string,
    })).isRequired,
    availableOffers: PropTypes.array,
    id: PropTypes.number.isRequired,
    eventKey: PropTypes.string.isRequired,
    onOfferSelected: PropTypes.func.isRequired,
};

OffersAccordionContent.defaultProps = {
    availableOffers: [],
};

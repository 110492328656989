import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as Sentry from "@sentry/react";
import { EnvLogLevels } from "./constants/LogLevels";

const isProduction = window.location.host === "et.greenlinepet.com";
const isLocalHost = window.location.host === "localhost:3000";
const isQA = !isProduction && !isLocalHost;
const environmentName = isProduction ? "production" : isQA ? "qa" : "localhost";

if (!window.ET_APP) {
    window.ET_APP = {
        environmentName,
        logLevel: EnvLogLevels[environmentName],
    };
}

if (!isLocalHost) {
    Sentry.init({
        dsn: "https://40e6081068f9658d54efbe5f3c1b4f11@o973525.ingest.us.sentry.io/4508066356854784",
        environment: environmentName,
        debug: false,
        attachStacktrace: true,
        ignoreErrors: [
            "ResizeObserver loop limit exceeded",
            "No matching state found in storage",
            "Unauthorized",
        ],
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

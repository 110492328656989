export default class LocalData {

    // Session ID
    static setSessionId(sessionId) {
        localStorage.setItem("sessionId", sessionId);
    }

    static getSessionId() {
        const sessionId = localStorage.getItem("sessionId");
        return sessionId === "undefined" ? null : sessionId;
    }

    static clearSessionId() {
        localStorage.removeItem("sessionId");
    }

    // Origin
    static setOrigin(origin) {
      localStorage.setItem("origin", origin);
    }

    static getOrigin() {
      return localStorage.getItem("origin");
    }

    static clearOrigin() {
      localStorage.removeItem("origin");
    }

    // ClientId
    static setClientId(clientId) {
      localStorage.setItem("clientId", clientId);
    }

    static getClientId() {
      return localStorage.getItem("clientId");
    }

    static clearClientId() {
      localStorage.removeItem("clientId");
    }

    // Clinic GUID
    static setClinicGuid(clinicGuid) {
        localStorage.setItem("clinicGuid", clinicGuid);
    }

    static getClinicGuid() {
        return localStorage.getItem("clinicGuid");
    }

    static clearClinicGuid() {
        localStorage.removeItem("clinicGuid");
    }

    // Pet Owner Id
    static setPetOwnerId(petOwnerId) {
        localStorage.setItem("petOwnerId", petOwnerId);
    }

    static getPetOwnerId() {
        return localStorage.getItem("petOwnerId");
    }

    static clearPetOwnerId() {
        localStorage.removeItem("petOwnerId");
    }


    static setUserFullName(userFullName) {
        localStorage.setItem("userFullName", userFullName);
    }

    static getUserFullName() {
        return localStorage.getItem("userFullName");
    }

    static clearUserFullName() {
        localStorage.removeItem("userFullName");
    }

    // Token
    static setAccessToken(accessToken) {
        localStorage.setItem("accessToken", accessToken);
    }

    static getAccessToken() {
        return localStorage.getItem("accessToken");
    }

    static clearAccessToken() {
        localStorage.removeItem("accessToken");
    }

    static setAccessTokenExpirationMs(accessTokenExpirationMs) {
        localStorage.setItem("accessTokenExpirationMs", accessTokenExpirationMs);
    }

    static getAccessTokenExpirationMs() {
        return localStorage.getItem("accessTokenExpirationMs");
    }

    static clearAccessTokenExpirationMs() {
        localStorage.removeItem("accessTokenExpirationMs");
    }

    static setConfetti(confetti) {
        localStorage.setItem("confetti", confetti);
    }

    static getConfetti() {
        return localStorage.getItem("confetti");
    }

    static clearConfetti() {
        localStorage.removeItem("confetti");
    }
}

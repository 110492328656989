import React, {createContext, useState, useEffect} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import get from "lodash/get";
import "./App.css";
import AppError from "./components/AppError";
import AuthenticatedRouteWrapper from "./components/routing/AuthenticatedRouteWrapper";
import CouponLibraryPage from "./pages/CouponLibraryPage";
import ComingSoonPage from "./pages/ComingSoonPage";
import DashboardPage from "./pages/DashboardPage";
import Header from "./components/Header";
import LandingPage from "./pages/LandingPage";
import LoadingSpinner from "./components/LoadingSpinner";
import LoginPage from "./pages/LoginPage";
import Modal from "./components/Modal";
import SurveyForm from "./forms/SurveyForm";
import * as requests from "./utils/requests";
import getSurveyModalHeaderText from "./utils/getSurveyModalHeaderText";
import logger from "./utils/logger";

const DEFAULT_APP_STATE = {
    isLoading: false,
    showSurvey: false,
    hasCompletedSurvey: false,
};

export const windowSizeContext = createContext();

export default function App() {
    const [appState, setAppState] = useState(DEFAULT_APP_STATE);
    const [featureFlags, setFeatureFlags] = useState(null);
    const [showFeatureFlagError, setShowFeatureFlagError] = useState(false);
    const isAppLoading = get(appState, "isLoading", false);
    const showComingSoonPage = get(featureFlags, "ShowComingSoonPage", false);
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        // add when mounted
        window.addEventListener("resize", handleWindowSizeChange);

        // return function to be called when unmounted
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        handleLoadFeatureFlags();
    }, []);

    const handleLoadFeatureFlags = async () => {
        // Show the spinner
        handleAppStateChanged({
            isLoading: true,
        });

        try {
            const featureFlagsResponse = await requests.loadFeatureFlags();
            if (featureFlagsResponse.Status && featureFlagsResponse.Status === 500) {
                throw new Error("Error loading feature flags: ", featureFlagsResponse.Detail);
            }
            setFeatureFlags(featureFlagsResponse);
        } catch (e) {
            logger.error("Error loading feature flags", e, true);
            setShowFeatureFlagError(true);
        } finally {
            handleAppStateChanged({
                isLoading: false,
            });
        }
    };

    const handleAppStateChanged = (changes) => {
        setAppState({
            ...appState,
            ...changes,
        });
    };

    if (!featureFlags) {
        if (showFeatureFlagError) {
            return (
                <div className="height-100 flex-row flex-center text-center padding-lg">
                    <AppError
                        errorMessage="Uh oh! There was an error loading the app. Please try again later."
                    />
                </div>
            );
        }
        return null;
    }

    return (
        <BrowserRouter>
            <windowSizeContext.Provider
                data-testid="app_root_component"
                value={{
                    isPhone: (width <= 600),
                    isTabletPortrait: (width <= 900),
                    isTabletLandscape: (width <= 1200),
                    windowWidth: width,
                }}
            >
                <div className="appRoot">
                    <Header
                        appState={appState}
                    />

                    {showComingSoonPage ? (
                        <ComingSoonPage/>
                    ) : (
                        <div className="pageContentContainer">
                            <div className="pageContent">
                                <Routes>
                                    <Route
                                        path={"/"}
                                        element={<LandingPage/>}
                                    />
                                    <Route
                                        path={"/app/clinic/:clinicId"}
                                        element={(
                                            <LoginPage
                                                appState={appState}
                                                onAppStateChanged={handleAppStateChanged}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={"/app/clinic/:clinicId/user/:petOwnerId"}
                                        element={(
                                            <AuthenticatedRouteWrapper>
                                                <DashboardPage
                                                    appState={appState}
                                                    onAppStateChanged={handleAppStateChanged}
                                                />
                                            </AuthenticatedRouteWrapper>
                                        )}
                                    />
                                    <Route
                                        path={"/app/clinic/:clinicId/user/:petOwnerId/offers/"}
                                        element={(
                                            <AuthenticatedRouteWrapper>
                                                <CouponLibraryPage
                                                    appState={appState}
                                                    onAppStateChanged={handleAppStateChanged}
                                                />
                                            </AuthenticatedRouteWrapper>
                                        )}
                                    />
                                </Routes>
                            </div>
                        </div>
                    )}

                    <LoadingSpinner show={isAppLoading}/>

                    <Modal
                        show={appState.showSurvey}
                        headerText={getSurveyModalHeaderText(appState)}
                        onClose={() => handleAppStateChanged({showSurvey: false})}
                        showFullCloseButton={appState.hasCompletedSurvey}
                    >
                        {appState.hasCompletedSurvey ? (
                            <div>Your responses have been submitted.</div>
                        ) : (
                            <SurveyForm
                                appState={appState}
                                onAppStateChanged={handleAppStateChanged}
                                onAfterSubmit={() => handleAppStateChanged({showSurvey: false})}
                            />
                        )}
                    </Modal>
                </div>
            </windowSizeContext.Provider>
        </BrowserRouter>
    );
}

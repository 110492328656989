import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./SurveyForm.module.scss";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import filter from "lodash/filter";
import map from "lodash/map";
import * as requests from "../utils/requests";
import AppError from "../components/AppError";
import SurveyQuestion from "../components/SurveyQuestion";
import logger from "../utils/logger";

export default function SurveyForm({ appState, onAppStateChanged, onAfterSubmit }) {
  const [showLoadError, setShowLoadError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [surveyData, setSurveyData] = useState(null);
  const [formData, setFormData] = useState({});

  const surveyId = get(surveyData, "surveyId");
  const surveyQuestions = get(surveyData, "questions", []);

  useEffect(() => {
    handleLoadSurvey();
  }, []);

  const handleLoadSurvey = async () => {
    // Show the spinner
    onAppStateChanged({
        isLoading: true,
    });

    try {
        const surveyResponse = await requests.loadSurvey();
        if (surveyResponse.Status && surveyResponse.Status === 500) {
            throw new Error("Error loading survey: ", surveyResponse.Detail);
        }

        const firstSurvey = surveyResponse[0];
        if (!!firstSurvey) {
          const surveyId = firstSurvey.clientSurveyId;
          const questions = firstSurvey.clientSurveyQuestions;
          // Format the JSON string into an actual JSON object, and order the questions by ID
          const formattedSurveyQuestions = orderBy(questions.map(question => ({
            ...question,
            questionConfigJson: JSON.parse(question.questionConfigJson),
          })), "clientSurveyQuestionId");

          setSurveyData({
            surveyId,
            questions: formattedSurveyQuestions,
          });
        }
    } catch (e) {
        logger.error("Error loading survey", e, true);
        setShowLoadError(true);
    } finally {
        onAppStateChanged({
            isLoading: false,
        });
    }
};

  const handleFormDataChanged = ({ name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitForm = async () => {
    // Show the spinner
    onAppStateChanged({
      isLoading: true,
    });

    try {
      const formattedSurveyResponses = map(surveyQuestions, ({ clientSurveyQuestionId }) => {
        return {
          surveyQuestionId: clientSurveyQuestionId,
          surveyQuestionResponse: formData[clientSurveyQuestionId],
        };
      });

      const surveyResponseStatus = await requests.submitSurvey(surveyId, formattedSurveyResponses);
      if (surveyResponseStatus === 204) {
        // Success!
        onAppStateChanged({
          hasCompletedSurvey: true,
          isLoading: false,
        });
      } else {
        throw new Error("Error submitting survey responses");
      }
    } catch (e) {
        logger.error("Error submitting survey responses", e, true);
        setShowSubmitError(true);
        onAppStateChanged({
          isLoading: false,
        });
    }
  };

  if (!surveyQuestions) {
    if (showLoadError) {
        return (
            <AppError
                errorMessage="Uh oh! There was an error loading the survey. Please try again later."
            />
        );
    }
    return null;
  }

  if (showSubmitError) {
    return (
        <AppError
            errorMessage="Uh oh! There was an error submitting your survey responses. Please try again later."
        />
    );
  }

  const getIsFormValid = () => {
    // Make a list of the survey question responses, and replace unanswered questions (or empty string values) with null
    const surveyQuestionResponsesWithNull = surveyQuestions.map(({ clientSurveyQuestionId }) => {
      const surveyQuestionResponse = get(formData, clientSurveyQuestionId, null);
      if (typeof surveyQuestionResponse === "string" && surveyQuestionResponse.trim() === "") {
        return null;
      }
      return surveyQuestionResponse;
    });

    // Form is valid when there are no nulls in the list (a.k.a., all questions have been answered)
    return filter(surveyQuestionResponsesWithNull, sqr => sqr === null).length === 0;
  };

  return (
    <div>
      <div className="form">
        {surveyQuestions.map(surveyQuestion => (
          <React.Fragment key={`survey-question-${surveyQuestion.clientSurveyQuestionId}`}>
            <div className={styles.formPrompt}>
              {surveyQuestion.questionText}
            </div>
            <SurveyQuestion
              name={surveyQuestion.clientSurveyQuestionId}
              value={formData[surveyQuestion.clientSurveyQuestionId] || ""}
              onChange={handleFormDataChanged}
              questionType={surveyQuestion.questionType}
              questionConfig={surveyQuestion.questionConfigJson}
            />
          </React.Fragment>
        ))}

        <div className="text-right">
          <button
            className="et-btn small"
            onClick={handleSubmitForm}
            disabled={!getIsFormValid()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

SurveyForm.propTypes = {
  onAfterSubmit: PropTypes.func,
};

import LocalData from "./LocalData";


const getSessionHeaders = (requiresAuth) => {
  const sessionId = LocalData.getSessionId();
  const origin = LocalData.getOrigin();
  const providedClientId = LocalData.getClientId();
  const accessToken = LocalData.getAccessToken() || null;

  const auth = requiresAuth ? {
    "Authorization": `Bearer ${accessToken}`
  } : {};

  const session = sessionId ? {
    "sessionId": sessionId
  } : {}

  const originHdr = origin ? {
    "originId": origin
  } : {}

  const clientHdr = providedClientId ? {
    "clientId": providedClientId
  } : {}


  return {
    "Content-Type": "application/json",
    "accept": "application/json",
    ...session,
    ...originHdr,
    ...clientHdr,
    ...auth,
  }
}

const getBaseApiUrl = () => {
  const isDevEnv = window.ET_APP?.environmentName === "localhost";
  if (isDevEnv) {
    return "https://zealous-coast-0ce72b410.3.azurestaticapps.net/api";
    // return "https://orange-sand-0474bd610.3.azurestaticapps.net/api";
  }
  return "/api";
};

export const loadFeatureFlags = async () => {
  const requestParameters = {
    method: "GET",
    headers: {
      ...getSessionHeaders(),
    }
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/features/`, requestParameters);
  return await response.json();
};

export const loadBannerAd = async () => {
  const requestParameters = {
    method: "GET",
    headers: getSessionHeaders(),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notifications/banners`, requestParameters);
  return await response.json();
}

// Credentials is an object that must contain either:
//   1. RemoteClientDisplayId, ClientLastName, clinicGuid, and origin="qr"
//   2. Only petownerGuid and origin="email"
export const login = async (credentials) => {
    const requestParameters = {
        method: "POST",
        body: JSON.stringify(credentials),
        headers: getSessionHeaders(),
    };
    const response = await fetch(`${getBaseApiUrl()}/v1/login/token`, requestParameters);
    return await response.json();
};

export const loadClinic = async (clinicId) => {
  const requestParameters = {
    method: "GET",
    headers: getSessionHeaders(),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/clinics/${clinicId}`, requestParameters);
  return await response.json();
};

export const loadClientOffers = async () => {
    const petOwnerId = LocalData.getPetOwnerId();
    const clinicGuid = LocalData.getClinicGuid();

    const requestParameters = {
      method: "GET",
      headers: getSessionHeaders(true),
    };
    const response = await fetch(`${getBaseApiUrl()}/v1/offers/clientoffers?petOwnerId=${petOwnerId}&clinicGuid=${clinicGuid}`, requestParameters);
    return await response.json();
};

export const loadNationalOffersForClinic = async () => {
    const clinicGuid = LocalData.getClinicGuid();
    const requestParameters = {
      method: "GET",
      headers: getSessionHeaders(true),
    };
    const response = await fetch(`${getBaseApiUrl()}/v1/offers/nationaloffers?clinicGuid=${clinicGuid}`, requestParameters);
    return await response.json();
};

export const loadSurvey = async () => {
  const petOwnerId = LocalData.getPetOwnerId();

  const requestParameters = {
    method: "GET",
    headers: getSessionHeaders(true),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notification/survey?petOwnerId=${petOwnerId}`, requestParameters);
  return await response.json();
};

export const submitSurvey = async (surveyId, surveyResponses) => {
  const petOwnerId = LocalData.getPetOwnerId();

  const requestBody = JSON.stringify({
    petOwnerId,
    surveyId,
    results: surveyResponses,
    responseDate: new Date().toISOString(),
  });

  const requestParameters = {
    method: "POST",
    body: requestBody,
    headers: getSessionHeaders(true),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notification/survey/results`, requestParameters);
  return response.status;
};

export const submitNotYourPetData = async (petName, species) => {
  const petOwnerId = LocalData.getPetOwnerId();
  const clinicGuid = LocalData.getClinicGuid();

  const requestBody = JSON.stringify({
    clinicGuid,
    petOwnerId,
    reasonType: "remove",
    petName,
    species,
  });

  const requestParameters = {
    method: "POST",
    body: requestBody,
    headers: getSessionHeaders(true),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notification/managepets`, requestParameters);
  return response.status;
};

export const submitRequestPetAdditionData = async (petName, species) => {
  const petOwnerId = LocalData.getPetOwnerId();
  const clinicGuid = LocalData.getClinicGuid();

  const requestBody = JSON.stringify({
    clinicGuid,
    petOwnerId,
    reasonType: "add",
    petName,
    species,
  });

  const requestParameters = {
    method: "POST",
    body: requestBody,
    headers: getSessionHeaders(true),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/notification/managepets`, requestParameters);
  return response.status;
};

import React, { useState, useEffect } from "react";
import styles from "./LoginPage.module.scss";
import classnames from "classnames";
import { useParams, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Modal from "../components/Modal";
import WatermarkBackground from "../components/WatermarkBackground";
import * as requests from "../utils/requests";
import * as parse from "../utils/parse";
import * as LoginOriginTypes from "../constants/LoginOriginTypes";
import AppError from "../components/AppError";
import LocalData from "../utils/LocalData";
import logger from "../utils/logger";

// const LOGIN_PAGE_AD_SRC = require("../images/login-page-ad.jpeg");
const LOGIN_PAGE_AD_SRC = require("../images/BIAHU-004397_CMK_2024_Greenline_TargetedWebsiteBanner_920x350.jpg");

export default function LoginPage ({ appState, onAppStateChanged }) {
  const [searchParams] = useSearchParams();
  const autoLoginPetOwnerId = searchParams.get("petOwnerId");
  const prepopulatedClientId = searchParams.get("clientId");
  const [formData, setFormData] = useState({ clientId: prepopulatedClientId });
  const [showLoginError, setShowLoginError] = useState(false);
  const [showLoadClinicError, setShowLoadClinicError] = useState(false);
  const [clinicData, setClinicData] = useState(null);
  // const [bannerImages, setBannerImages] = useState([""]);
  const [showClientIdModal, setShowClientIdModal] = useState(false);
  const navigate = useNavigate();
  const isFormValid = !!formData.lastName && !!formData.clientId;
  const params = useParams();

  const handleLoadClinic = async (clinicId) => {
    // Show the spinner
    onAppStateChanged({
      isLoading: true,
    });

    try {
      if (!!autoLoginPetOwnerId) { //autologin only for Greenline Emails
        LocalData.setOrigin(LoginOriginTypes.EMAIL)
      } else {
        LocalData.setOrigin(!!prepopulatedClientId ? LoginOriginTypes.WUNDERMAN_THOMPSON : LoginOriginTypes.QR);
      }
      if (prepopulatedClientId){
        LocalData.setClientId(prepopulatedClientId);
      } else {
        LocalData.clearClientId();
      }
      // Load all the client offers and save the patient data
      const clinicResponse = await requests.loadClinic(clinicId);
      if (clinicResponse.Status && clinicResponse.Status === 500) {
        throw new Error("Error loading clinic: ", clinicResponse.Detail);
      }
      setClinicData(clinicResponse);
      LocalData.setClinicGuid(clinicResponse.clinicGuid);
      LocalData.setSessionId(clinicResponse.sessionId);
    } catch (e) {
      logger.error("Error loading clinic", e, true);
      setShowLoadClinicError(true);
    } finally {
      onAppStateChanged({
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    if (!autoLoginPetOwnerId) {
      const { clinicId } = params;
      handleLoadClinic(clinicId);
    }
    // handleLoadBanner();
  }, []);

  // Hook to automatically log in the user if petOwnerId is in the query params (a.k.a., from an email link)
   //only emails from GLN will have ownerid
  useEffect(() => {
    if (!!autoLoginPetOwnerId) {
      LocalData.setOrigin(LoginOriginTypes.EMAIL);
      handleLoginUser({
        petownerGuid: autoLoginPetOwnerId,
        origin: LoginOriginTypes.EMAIL,
      });
    }
  }, [autoLoginPetOwnerId, clinicData]);

  // const handleLoadBanner = async () => {
  //   onAppStateChanged({
  //     isLoading: true,
  //   });
  //
  //   try {
  //     const imageList = await loadBannerAd();
  //     setBannerImages(imageList);
  //   } catch (e) {
  //     logger.error("Error loading banner", e, true);
  //   } finally {
  //     onAppStateChanged({
  //       isLoading: false,
  //     })
  //   }
  // }



  const handleFormDataChanged = ({ name, value }) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setShowLoginError(false);
  };

  const handleInputChanged = (e, name) => {
    e.preventDefault();
    const { value } = e.target;

    handleFormDataChanged({
      name,
      value,
    });
  };

  const handleLoginUser = async (credentials) => {
    // Show the spinner
    onAppStateChanged({
      ...formData,
      isLoading: true,
    });

    try {
      // Log in the user and save the access token to state
      const loginResponse = await requests.login(credentials);
      if (loginResponse.Status && loginResponse.Status === 400) {
        // Error
        setShowLoginError(true);
        throw new Error("Invalid credentials");
      } else {
        // Success
        const accessTokenJson = parse.parseJsonFromAccessToken(loginResponse.accessToken);
        const petOwnerId = accessTokenJson.petownerid;

        // Calculate when the access token will expire
        const currentTimeMs = Date.now();
        const accessTokenDurationMs = loginResponse.expiration * 1000; // It comes back as seconds in the api, but we want to convert it to milliseconds
        const accessTokenExpirationMs = currentTimeMs + accessTokenDurationMs;

        // Save some data to localStorage to persist if the page gets reloaded after logging in
        LocalData.setAccessToken(loginResponse.accessToken);
        LocalData.setAccessTokenExpirationMs(accessTokenExpirationMs);
        LocalData.setPetOwnerId(petOwnerId);
        LocalData.setClinicGuid(loginResponse.clinicGuid);
        LocalData.setUserFullName(accessTokenJson.name);

        // Go to the dashboard
        navigate(`/app/clinic/${loginResponse.clinicGuid}/user/${petOwnerId}`);
      }
    } catch (e) {
      logger.error("Error authenticating user", e, true);
    } finally {
      onAppStateChanged({
        isLoading: false,
      });
    }
  };

  const handleSubmit = async () => {
    handleLoginUser({
      RemoteClientDisplayId: formData.clientId,
      ClientLastName: formData.lastName,
      clinicGuid: LocalData.getClinicGuid(),
      origin: !!prepopulatedClientId ? LoginOriginTypes.WUNDERMAN_THOMPSON : LoginOriginTypes.QR,
    });
  };

  const checkEnterKeyDown = (e) => {
    if (isFormValid && e.key === "Enter") {
      handleSubmit();
    }
  };

  if (!clinicData) {
    if (showLoadClinicError) {
        return (
            <AppError
                errorMessage="Uh oh! There was an error loading the clinic. Please try again later."
            />
        );
    }
    return null;
}

  return (
    <WatermarkBackground>
      <div className="height-100 flex-column">
        <img
          alt="Login Page Ad"
          className={styles.adImage}
          src={LOGIN_PAGE_AD_SRC}
        />

        <div className="flex-2 flex-column padding-xl">
          <div className="container flex-column flex-1">
            <div className={classnames("form position-relative flex-column flex-center", styles.formContainer)}>
              <h3 className={classnames("padding-top-bottom-xl", styles.header)}>{clinicData.clinicName}</h3>
              <div className="width-100 position-relative">
                {showLoginError && (
                  <div className={styles.errorMessage}>
                    Invalid credentials. Please try again.
                  </div>
                )}
                <input
                  className={classnames("fancy", {"error": showLoginError})}
                  type="text"
                  placeholder="Enter Last Name"
                  value={formData.lastName || ""}
                  onChange={(e) => handleInputChanged(e, "lastName")}
                  onKeyDown={checkEnterKeyDown}
                />
              </div>
              <input
                className={classnames("fancy", {"error": showLoginError})}
                type="text"
                placeholder="Enter Client ID"
                value={formData.clientId || ""}
                onChange={(e) => handleInputChanged(e, "clientId")}
                onKeyDown={checkEnterKeyDown}
              />

              <div
                className="link text-sm"
                onClick={() => setShowClientIdModal(true)}
              >
                What is My Client ID?
              </div>

              <button
                className="et-btn"
                type="submit"
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                Login
              </button>
            </div>
          </div>
        </div>

        <Modal
          show={showClientIdModal}
          headerText="What is My Client ID?"
          onClose={() => setShowClientIdModal(false)}
        >
          <p>
            This is your unique identifier within your clinic's practice management system, this can be found on your most recent invoice, and is also in your client account.
          </p>
          <p>
            The fastest way to retrieve your client ID is to ask the front desk staff.
          </p>
        </Modal>
      </div>
    </WatermarkBackground>
  );
}

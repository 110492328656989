import React from "react";
import PropTypes from "prop-types";
import OffersAccordionContent from "./OffersAccordionContent";
import Accordion from "react-bootstrap/Accordion";

export default function OffersAccordion({ offers, onOfferSelected }) {
  return (
    <div className="spaced-content-vertical">
      {offers.map((offer, index) => {
        const parsedGroupDescriptionItems = JSON.parse(offer.groupDescription);
        return (
          <Accordion
            key={`offer-accordion-${index}`}
            defaultActiveKey="item_0"
            flush
          >
            <OffersAccordionContent
              id={offer.displayGroupId}
              eventKey={`item_${index}`}
              groupDescriptionItems={parsedGroupDescriptionItems}
              availableOffers={offer.displayData}
              onOfferSelected={onOfferSelected}
            />
          </Accordion>
        );
      })}
    </div>
  );
}

OffersAccordion.propTypes = {
  offers: PropTypes.array.isRequired,
  onOfferSelected: PropTypes.func.isRequired,
};

export const DEBUG = "debug";
export const INFO = "info";
export const WARN = "warn";
export const ERROR = "error";

export const checkLevel = (envLogLevel, requiredLogLevel) => {
    switch (requiredLogLevel) {
        case DEBUG: // console.log
            return envLogLevel === DEBUG;
        case INFO: // console.info
            return envLogLevel === DEBUG || envLogLevel === INFO;
        case WARN: // console.warn
            return envLogLevel !== ERROR;
        default: // console.error
            return true;
    }
}

export const EnvLogLevels = {
    production: WARN,
    qa: INFO,
    localhost: DEBUG,
}
